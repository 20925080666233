import React from "react"

import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"
import Onecol from '../components/onecol'
import Twocol from '../components/twocol'
import Cards from '../components/cards'
import ImageCTA from '../components/imageCTA'
import ButtonCTA from '../components/buttonCTA'
import { graphql } from 'gatsby'

import Banner from '../components/banner'
import HomeBanner from '../images/home-banner-2.jpg' 

import { LazyLoadImage } from 'react-lazy-load-image-component';
import Jersey from '../images/jersey.jpg'
import Printing from '../images/printing.jpg'
import Beginnings from '../images/humble-beginings.jpg'
import Computer from '../images/macbook.jpg'

import { Printer } from 'tabler-icons-react';
import { Car } from 'tabler-icons-react';
import { BuildingStore } from 'tabler-icons-react';
import { DeviceDesktop } from 'tabler-icons-react';
import { Shirt } from 'tabler-icons-react';

export default function Home({data}) {

  return (
    <Layout>
      <SEO 
          title="Home" description="PROS Marketing has been in business for 20 years. We are your one stop digital marketing & print shop in Midland, Ontario. Connect with us for affordable prices, home-town customer service & quality business supports."
        />
      
      <Banner
        title="PROS Marketing is more than just a print company."
        description="Helping you grow your business by utilizing a number of powerful marketing tools with affordable prices and trusted customer service."
        img={HomeBanner}
      />

      {data.allStrapiHome.edges.map(({ node }) => (
        
        <>
        <Onecol
          key={node.home_intro.id}
          title={node.home_intro.one_col_title}
          description={node.home_intro.one_col_description}
        />

        <Twocol
          key={node.home_two_col.id}
          image={Printing}
          intro={node.home_two_col.two_col_intro}
          iconOne={
            <Printer 
              size={40} 
              strokeWidth={2} 
              color={'#DD2E39'} 
            />
          }
          titleOne={node.home_two_col.title_one}
          linkOne="/printing"
          descriptionOne={node.home_two_col.description_one}
          iconTwo={
            <BuildingStore
              size={40}
              strokeWidth={2}
              color={'#DD2E39'}
            />          
          }
          titleTwo={node.home_two_col.title_two}
          linkTwo="/advertising"
          descriptionTwo={node.home_two_col.description_two}
        />

        <Cards 
          key={node.home_cards.id}
          linkOne="/web"
          iconOne={
            <DeviceDesktop
              size={40}
              strokeWidth={2}
              color={'#DD2E39'}
            />
          }
          titleOne={node.home_cards.title_one}
          descriptionOne={node.home_cards.description_one}
          linkTwo="/promotional"
          iconTwo={
            <Shirt
              size={40}
              strokeWidth={2}
              color={'#DD2E39'}
            />
          }
          titleTwo={node.home_cards.title_two}
          descriptionTwo={node.home_cards.description_two}
          linkThree="/wraps-decals"
          iconThree={
            <Car
              size={40}
              strokeWidth={2}
              color={'#DD2E39'}
            />          
          }
          titleThree={node.home_cards.title_three}
          descriptionThree={node.home_cards.description_3}
        />

        <ButtonCTA />

        <Banner
          small
          title="Our Humble Beginnings."
          description="PROS Marketing started off in digital services almost 3 decades ago and we have since enhanced our focus to become a 'one stop' digital and print marketing experience for our customers."
          img={Beginnings}
        />
    
        <ImageCTA
          title="We provide custom, professional websites and digital services."
          image={Computer}
          link="/contact-us"
          linkText="inquire today"
        />
        </>
      ))}  
    </Layout>
  )
  
}

// export default Home

export const query = graphql`
query Home {
  allStrapiHome {
    edges {
      node {
        home_hero {
          id
          hero_title
          hero_description
        }
        home_intro {
          id
          one_col_title
          one_col_description
        }
        home_two_col {
          id
          title_one
          description_one
          title_two
          description_two
        }
        home_cards {
          id
          title_one
          description_one
          title_two
          description_two
          title_three
          description_3
        }
        bottom_banner {
          id
          banner_title
          banner_description
        }
      }
    }
  }
}


`
