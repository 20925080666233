import React from 'react'
import { Container, Card, CardContent } from '@material-ui/core'
import { Link } from 'gatsby'

export default function Cards(props) {
    return (
        <Container className="flex justify-between flex-wrap pb-40">
        <Card variant="outlined" className="card">
          <Link to={props.linkOne}>
            <CardContent>
              {props.iconOne}
              <h6 className="my-5">{props.titleOne}</h6>
              <p>{props.descriptionOne}</p>
            </CardContent>
          </Link>
        </Card>    
        <Card variant="outlined" className="card">
          <Link to={props.linkTwo}>
            <CardContent>
              {props.iconTwo}
              <h6 className="my-5">{props.titleTwo}</h6>
              <p>{props.descriptionTwo}</p>
            </CardContent>
          </Link>        
        </Card>    
        <Card variant="outlined" className="card">
          <Link to={props.linkThree}>
            <CardContent>
              {props.iconThree}
              <h6 className="my-5">{props.titleThree}</h6>
              <p>{props.descriptionThree}</p>
            </CardContent>
          </Link>
        </Card>    
      </Container>        
    )
}
