import React from 'react'
import { Link } from 'gatsby'
import { Container, Grid } from '@material-ui/core'
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function TwoCol(props) {
    return (
        <div className={`${props.grey === true ? 'grey-bg py-40' : 'white-bg' } mb-40`}>
          <Container>
          <Grid items container className={`twocol-list flex justify-between items-center ${props.textLeft === true ? 'md:flex-row-reverse' : null }`}>
            <Grid md={6} sm={12}>
            <LazyLoadImage
              src={props.image}
              effect="blur"
            />
            </Grid>
            <Grid md={5} sm={12}>
              <h4>{props.intro}</h4>
              <div className="twocol-item">
                <Link className="twocol-block" to={props.linkOne}>
                  <div className="icon">
                    {props.iconOne}
                  </div>
                  <div className="text">
                    <h6>{props.titleOne}</h6>
                    <p>{props.descriptionOne}</p>
                  </div>          
                </Link>
                <Link className="twocol-block" to={props.linkTwo}>
                  <div className="icon">
                    {props.iconTwo}
                  </div>
                  <div className="text">
                    <h6>{props.titleTwo}</h6>
                    <p>{props.descriptionTwo}</p>
                  </div>          
                </Link>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    )
}
